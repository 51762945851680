import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from '@headlessui/react'
import { InquiryForm } from "./Contact";

import { ArrowRightCircleIcon } from '@heroicons/react/24/outline'

const distributor_img = require('../assets/images/distributors.png')
const manufacturer_img = require('../assets/images/pcb.png')
const eCooker = require('../assets/images/ecooker.png')

export default function Solutions() {

  const [open, setOpen] = useState(false)
  const [inquiry, setInquiry] = useState()
  const cancelButtonRef = useRef(null)

  const PricingForm = () => {

    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-[6000]" initialFocus={cancelButtonRef} onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl w-full">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center w-full sm:mx-4 sm:mt-0 sm:text-left">
                        <Dialog.Title as="h3" className="border-b pb-3 text-base app-semibold text-gray-900">
                          Send Inquiry
                        </Dialog.Title>
                        <InquiryForm inquiry={inquiry} />
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }

  return (
    <div className="bg-gray-100 py-12 sm:py-32" id='solutions'>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="max-w-2xl sm:ml-12 ml-3">
          <h4 className="text-2xl app-bold leading-7 text-gray-700 sm:text-6xl">
            Afodabbo offers a game changing solution to accelerate eCooking
          </h4>
        </div>

        <PricingForm />

        <div className="bg-gray-100">
          <div className="mx-auto max-w-7xl py-8 sm:px-6 sm:pb-20 lg:px-8">
            <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl rounded-xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
              <svg
                viewBox="0 0 1024 1024"
                className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
                aria-hidden="true"
              >
                <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
                <defs>
                  <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                    <stop stopColor="#7775D6" />
                    <stop offset={1} stopColor="#E935C1" />
                  </radialGradient>
                </defs>
              </svg>
              <div className="mx-auto max-w-md text-left lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
                <h2 className="text-3xl app-bold tracking-tight text-white sm:text-4xl">
                  PAYG eCOOKERS
                </h2>
                <p className="mt-6 text-md leading-6 text-gray-400 text-justify">
                  We sell highly tested and certified PAYG enabled eCookers
                  to distributors, with OEM services.
                </p>
                <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                  <button
                    onClick={() => {
                      setInquiry('Order eCooker')
                      setOpen(true)
                    }}
                    className="rounded-md bg-[#2E58A6] px-8 py-2.5 text-sm app-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                  >
                    Order Now
                  </button>
                </div>
              </div>
              <div className="relative mt-16 h-80 lg:mt-8">
                <img
                  className="absolute left-0 top-0 w-[22rem] lg:w-[38rem] sm:w-[38rem] md:w-[38rem] max-w-none rounded-xl bg-white/5 ring-1 ring-white/10"
                  src={eCooker}
                  alt="eCooker"
                  width={1824}
                  height={1080}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Distributors */}
        <div className="bg-gray-100">
          <div className="mx-auto max-w-7xl py-0 sm:px-6 sm:pb-20 lg:px-8">
            <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl rounded-xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
              <svg
                viewBox="0 0 1024 1024"
                className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
                aria-hidden="true"
              >
                <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
                <defs>
                  <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                    <stop stopColor="#7775D6" />
                    <stop offset={1} stopColor="#E935C1" />
                  </radialGradient>
                </defs>
              </svg>
              <div className="mx-auto max-w-md text-left lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
                <h2 className="text-3xl app-bold tracking-tight text-white sm:text-4xl">
                  eCOOKING SOFTWARE SOLUTION
                </h2>
                <p className="mt-6 text-md leading-6 text-gray-400 text-justify">
                  Our robust software suite supports distributors to efficiently manage their PAYG eCooking business. Distributors can easily cofigure their rental fees (based on hours, days, usage etc),
                  the eCooker is automatically locked when a customer defaulted and unlocked when the outstanding amount is settled in full.
                </p>
                <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                  <a
                    href="#software_features"
                    className="rounded-md bg-[#2E58A6] px-8 py-2.5 text-sm app-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                  >
                    Find Out More
                  </a>
                </div>
              </div>
              <div className="relative mt-16 h-80 lg:mt-8">
                <img
                  className="absolute left-0 top-0 w-[22rem] lg:w-[38rem] sm:w-[38rem] md:w-[38rem] max-w-none rounded-xl bg-white/5 ring-1 ring-white/10"
                  src={distributor_img}
                  alt="App screenshot"
                  width={1824}
                  height={1080}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Manufacturers */}
        <div className="bg-gray-100">
          <div className="mx-auto max-w-7xl pt-8 lg:pt-0 sm:pt-20 sm:px-6 lg:px-8">
            <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl rounded-xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
              <svg
                viewBox="0 0 1024 1024"
                className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
                aria-hidden="true"
              >
                <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
                <defs>
                  <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                    <stop stopColor="#7775D6" />
                    <stop offset={1} stopColor="#E935C1" />
                  </radialGradient>
                </defs>
              </svg>
              <div className="mx-auto max-w-md text-left lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
                <h2 className="text-3xl app-bold tracking-tight text-white sm:text-4xl">
                  eCOOKING HARDWARE SOLUTION
                </h2>
                <p className="mt-8 text-md leading-6 text-gray-400 text-justify lg:text-justify sm:text-justify">
                  <ol>
                    <li>
                      <div className='flex mb-3 items-start'>
                        <ArrowRightCircleIcon className='h-10 w-10 mr-3 text-white' />
                        <div>
                          <b className='text-gray-200'>PAYG Enabled eCookers.</b><br></br>
                          We help distributors with PAYG eCookers to integrate into our platform
                        </div>
                      </div>
                    </li><br></br>

                    <li>
                      <div className='flex mb-3 items-start'>
                        <ArrowRightCircleIcon className='h-10 w-10 mr-3 text-white' />
                        <div>
                          <b className='text-gray-200'>Transition to PAYG.</b><br></br>
                          We help traditional eCooker distributors to change their product into PAYG
                        </div>
                      </div>
                    </li>
                  </ol>
                </p>
                <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                  <button
                    onClick={() => {
                      setInquiry('Book Hardware Support')
                      setOpen(true)
                    }}
                    className="rounded-md bg-[#2E58A6] px-8 py-2.5 text-sm app-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                  >
                    Book Hardware Support
                  </button>
                </div>
              </div>
              <div className="relative mt-16 h-80 lg:mt-8">
                <img
                  className="absolute left-0 top-0 w-[24rem] lg:w-[40rem] sm:w-[38rem] md:w-[38rem] max-w-none rounded-xl bg-white/5 ring-1 ring-white/10"
                  src={manufacturer_img}
                  alt="Manufacturers"
                  width={1824}
                  height={1080}
                />
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
