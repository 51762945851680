import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { userAuth } from '../utils/auth';

export const InquiryForm = ({ inquiry }) => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const options = countryList().getData();

  const [accessToken, setAccessToken] = useState()

  useEffect(() => {
    userAuth().then((data) => { setAccessToken(data.accessToken) })
    console.log(accessToken)
  }, [accessToken])

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const data = {};

    formData.forEach((value, key) => {
      data[key] = value;
    });

    if (accessToken) {
      const response = await fetch('https://api.prod.afodabbo.com/vendors/messaging/email/website', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': accessToken,
          'Header': 'Bearer ' + accessToken
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        console.log('Form submitted successfully.');
      } else {
        console.error('Form submission failed.');
      }
    }

  };


  return (
    <form className="mx-auto mt-5 max-w-xl sm:mt-5" onSubmit={handleSubmit}>
      <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
        <div className="sm:col-span-2">
          <label htmlFor="fullName" className="block text-sm app-semibold leading-6 text-gray-900">
            Full name
          </label>
          <div className="mt-2.5">
            <input
              type="text"
              name="fullName"
              id="fullName"
              autoComplete="given-name"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <label htmlFor="email" className="block text-sm app-semibold leading-6 text-gray-900">
            Email
          </label>
          <div className="mt-2.5">
            <input
              type="email"
              name="email"
              id="email"
              autoComplete="email"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <label htmlFor="country" className="block text-sm app-semibold leading-6 text-gray-900">
            Country
          </label>
          <div className="relative mt-2.5">
            <Select
              id="country"
              name="country"
              options={options}
              value={selectedCountry}
              onChange={handleCountryChange}
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <label htmlFor="phoneNumber" className="block text-sm app-semibold leading-6 text-gray-900">
            Telephone number
          </label>
          <div className="relative mt-2.5">
            <input
              type="tel"
              name="phoneNumber"
              id="phoneNumber"
              autoComplete="tel"
              className="block w-full rounded-md border-0 px-3 py-2 mt-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder={selectedCountry ? selectedCountry.label : ''}
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <label htmlFor="organisation" className="block text-sm app-semibold leading-6 text-gray-900">
            Organization
          </label>
          <div className="mt-2.5">
            <input
              type="text"
              name="organisation"
              id="organisation"
              autoComplete="organization"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <label htmlFor="sector" className="block text-sm app-semibold leading-6 text-gray-900">
            Sector
          </label>
          <div className="mt-2.5">
            <input
              type="text"
              name="sector"
              id="sector"
              autoComplete="organization"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <label htmlFor="website" className="block text-sm app-semibold leading-6 text-gray-900">
            Website
          </label>
          <div className="mt-2.5">
            <input
              type="text"
              name="website"
              id="website"
              autoComplete="organization"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        {inquiry && <div className="sm:col-span-2">
          <label htmlFor="inquiry" className="block text-sm app-semibold leading-6 text-gray-900">
            Inquiry
          </label>
          <div className="mt-2.5">
            <input
              type="text"
              name="inquiry"
              id="inquiry"
              disabled
              value={inquiry}
              autoComplete="inquiry"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>}
        <div className="sm:col-span-2">
          <label htmlFor="message" className="block text-sm app-semibold leading-6 text-gray-900">
            Message
          </label>
          <div className="mt-2.5">
            <textarea
              name="message"
              id="message"
              rows={4}
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              defaultValue={''}
            />
          </div>
        </div>
      </div>
      <div className="mt-10">
        <button
          type="submit"
          className="block w-full rounded-xl bg-[#2E58A6] px-3.5 py-4 text-center text-md app-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Send Inquiry
        </button>
      </div>
    </form>
  )
}

export const ContactForm = () => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedPartnership, setSelectedPartnership] = useState('');

  const [accessToken, setAccessToken] = useState()

  useEffect(() => {
    userAuth().then((data) => { setAccessToken(data.accessToken) })
    console.log(accessToken)
  }, [accessToken])

  // Create an array of country options
  const options = countryList().getData();

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const data = {};

    formData.forEach((value, key) => {
      data[key] = value;
    });

    if (accessToken) {
      const response = await fetch('https://api.prod.afodabbo.com/vendors/messaging/email/website', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': accessToken,
          'Header': 'Bearer ' + accessToken
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        console.log('Form submitted successfully.');
      } else {
        console.error('Form submission failed.');
      }
    }

  };

  return (
    <form className="mx-auto mt-16 max-w-xl sm:mt-20 p-5 shadow rounded-lg" onSubmit={handleSubmit}>
      <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
        <div className="sm:col-span-2">
          <label htmlFor="fullName" className="block text-sm app-semibold leading-6 text-gray-900">
            Full name
          </label>
          <div className="mt-2.5">
            <input
              type="text"
              name="fullName"
              id="fullName"
              autoComplete="given-name"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <label htmlFor="email" className="block text-sm app-semibold leading-6 text-gray-900">
            Email
          </label>
          <div className="mt-2.5">
            <input
              type="email"
              name="email"
              id="email"
              autoComplete="email"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <label htmlFor="country" className="block text-sm app-semibold leading-6 text-gray-900">
            Country
          </label>
          <div className="relative mt-2.5">
            <Select
              id="country"
              name="country"
              options={options}
              value={selectedCountry}
              onChange={handleCountryChange}
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <label htmlFor="phoneNumber" className="block text-sm app-semibold leading-6 text-gray-900">
            Telephone number
          </label>
          <div className="relative mt-2.5">
            <input
              type="tel"
              name="phoneNumber"
              id="phoneNumber"
              autoComplete="tel"
              className="block w-full rounded-md border-0 px-3 py-2 mt-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder={selectedCountry ? selectedCountry.label : ''}
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <label htmlFor="organisation" className="block text-sm app-semibold leading-6 text-gray-900">
            Organization
          </label>
          <div className="mt-2.5">
            <input
              type="text"
              name="organisation"
              id="organisation"
              autoComplete="organization"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <label htmlFor="sector" className="block text-sm app-semibold leading-6 text-gray-900">
            Sector
          </label>
          <div className="mt-2.5">
            <input
              type="text"
              name="sector"
              id="sector"
              autoComplete="organization"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <label htmlFor="website" className="block text-sm app-semibold leading-6 text-gray-900">
            Website
          </label>
          <div className="mt-2.5">
            <input
              type="text"
              name="website"
              id="website"
              autoComplete="organization"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div className="sm:col-span-2">
          <label htmlFor="typeOfPartnership" className="block text-sm app-semibold leading-6 text-gray-900">
            Type of Partnership
          </label>
          <div className="mt-2.5">
            <select
              id="typeOfPartnership"
              name="typeOfPartnership"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              onChange={(e) => setSelectedPartnership(e.target.value)}
            >
              <option>Investor</option>
              <option>Distributor</option>
              <option>Manufacturer</option>
              <option>Media</option>
              <option>Other</option>
            </select>
          </div>
        </div>
        {(selectedPartnership === 'Distributor' || selectedPartnership === 'Manufacturer') && <div className="sm:col-span-2">
          <label htmlFor="interest" className="block text-sm app-semibold leading-6 text-gray-900">
            Interest
          </label>
          <div className="mt-2.5">
            <select
              id="interest"
              name="interest"
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            >
              <option>Software Integration</option>
              <option>Hardware Integration</option>
              <option>Module Integration</option>
              <option>Other</option>
            </select>
          </div>
        </div>}
        <div className="sm:col-span-2">
          <label htmlFor="message" className="block text-sm app-semibold leading-6 text-gray-900">
            Message
          </label>
          <div className="mt-2.5">
            <textarea
              name="message"
              id="message"
              rows={4}
              className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              defaultValue={''}
            />
          </div>
        </div>
        {/* <Switch.Group as="div" className="flex gap-x-4 sm:col-span-2">
            <div className="flex h-6 items-center">
              <Switch
                checked={agreed}
                onChange={setAgreed}
                className={classNames(
                  agreed ? 'bg-indigo-600' : 'bg-gray-200',
                  'flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                )}
              >
                <span className="sr-only">Agree to policies</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    agreed ? 'translate-x-3.5' : 'translate-x-0',
                    'h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out'
                  )}
                />
              </Switch>
            </div>
            <Switch.Label className="text-sm leading-6 text-gray-600">
              By selecting this, you agree to our{' '}
              <a href="#" className="app-semibold text-indigo-600">
                privacy&nbsp;policy
              </a>
              .
            </Switch.Label>
          </Switch.Group> */}
      </div>
      <div className="mt-10">
        <button
          type="submit"
          className="block w-full rounded-xl bg-[#2E58A6] px-3.5 py-4 text-center text-md app-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Let's talk
        </button>
      </div>
    </form>
  )
}

export default function Contact() {

  return (
    <div className="isolate bg-white px-6 py-24 sm:py-32 lg:px-8" id='contact'>
      <div
        className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
        aria-hidden="true"
      >
        <div
          className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl app-bold tracking-tight text-gray-900 sm:text-4xl">Contact Us</h2>
        <p className="app-medium text-gray-600 mt-2">To Change Lives of Millions</p>
      </div>

      {/* Form */}
      <ContactForm />
    </div>
  )
}
