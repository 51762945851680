import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Hero from './components/Hero';
import Clients from './components/Clients';
import Solutions from './components/Solutions';
import Features from './components/Features';
import Pricing from './components/Pricing';
import Impact from './components/Impact';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Download from './components/Download';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={
            <>
              <Hero />
              <Clients />
              <Solutions />
              <Features />
              <Pricing />
              <Impact />
              <Contact />
              <Footer />
            </>
          } />
          <Route path="/download" element={<Download />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
