const firewood = require('../assets/images/firewood.jpg')
const founder = 'https://firebasestorage.googleapis.com/v0/b/dyme-84d67.appspot.com/o/Afodabbo%2FFounder.jpeg?alt=media&token=01e0a873-5ccc-4cc9-9811-52d8dc4abc6a'

const mpika = require('../assets/images/mpika.png')
const smartpika = 'https://firebasestorage.googleapis.com/v0/b/dyme-84d67.appspot.com/o/Afodabbo%2Fsmartpika.jpeg?alt=media&token=ea1fc9d9-6c19-43b9-a5c7-a9ca65ed1e7e'
const kopagas = require('../assets/images/kopagas.png')
const mecs = require('../assets/images/mecs.png')
const airtel = require('../assets/images/airtel.png')
const uncdf = require('../assets/images/uncdf.png')
const ministry = require('../assets/images/ministry_of_energy.jpg')
const gecco = require('../assets/images/gecco.png')

const client_images = [mpika, smartpika, gecco, kopagas, mecs, airtel, uncdf, ministry]

export default function Clients() {
  return (
    <div className="bg-white sm:py-24 py-12 border" id='clients'>
      {/* Inspiration */}
      <div class="container mx-auto md:px-6" id='inspiration'>
        <section class="mb-2 text-center md:text-left">
          <div class="block rounded-2xl bg-[#2E58A6] shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
            <div class="flex flex-wrap items-center">
              <div
                class="block w-full shrink-0 grow-0 basis-auto md:w-4/12 lg:flex">
                <img
                  src={firewood}
                  alt="Unclean Cooking"
                  class="lg:ml-6 w-full md:ml-6 rounded-2xl" />
              </div>
              <div class="w-full shrink-0 grow-0 basis-auto md:w-8/12">
                <div class="px-6 py-12 md:px-12">
                  <h1 className="text-center text-2xl app-semibold leading-8 text-gray-100">
                    Our Inspiration
                  </h1>
                  <p className="mt-6 text-justify text-sm leading-5 lg:p-6 text-gray-200 app-light">
                    More than 200 million people in Sub Saharan Africa are connected to the grid but struggle to access modern energy services as the current market for eCookers
                    is filled with expensive products which are not affordable to the majority.<br></br> <br></br>
                    Traditional leasing or cash collection programs for eCookers have proven too costly or difficult to manage & effectively scale. The growth of mobile money presents an opportunity to leverage this infrastructure for distributors to provide financing inexpensively and securely at scale.                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Why Us */}
      <div class="container mx-auto md:px-6">
        <section class="my-8 text-center md:text-left">
          <div class="block rounded-2xl bg-gray-200 border">
            <div class="flex flex-wrap items-center">
              <div
                class="block w-full shrink-0 grow-0 basis-auto md:w-4/12 lg:flex">
                <img
                  src={founder}
                  alt="Founder"
                  class="lg:ml-6 w-full md:ml-6 rounded-2xl" />
              </div>
              <div class="w-full shrink-0 grow-0 basis-auto md:w-8/12">
                <div class="px-6 py-12 md:px-12">
                  <h1 className="text-center text-2xl app-semibold leading-8 text-gray-900">
                    Why Afodabbo should be your PAYG eCooking Partner?
                  </h1>
                  <p className="mt-6 text-justify text-sm leading-5 lg:p-6 text-gray-600 app-light">
                    Afodabbo is the only company in the world with real PAYG experience in the clean cooking sector. Our mission is to unlock modern energy cooking solutions to millions, we know this cannot be done by one
                    company alone.<br></br>
                    <br></br>
                                                                                                                                         We are leveraging, our intellectual property rights to ensure product quality, consumer safety
                                                                                                                                         and compliance with international standards. We are licencing our intellectual property rights under fair,
                                                                                                                                         reasonable and non - discriminatory terms to any company or enterprise wishing to utilize Afodabbo platform or buy our PAYG enabled eCookers.
                    The company is led by Andron Mendes - the original inventor of KopaGas
                    PAYGo LPG smart meters which was sold to Circle Gas (UK) in a USD 25 million transaction.<br></br> <br></br>

                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Clients */}
      <center className="px-6 lg:px-8" id='partners'>
        <div class="px-6 pt-24 md:px-12">
          <h1 className="text-center text-3xl app-semibold leading-8 text-gray-900">
            Our Partners
          </h1>
          <p className="mt-6 lg:mx-32 text-center text-md app-medium leading-6 lg:p-6 text-gray-600">
            We are trusted by key players in the eCooking industry, who are working hard to
            enable a mass transition to eCooking.
          </p>
        </div>
        <div className="mx-auto mt-12 grid lg:grid-flow-col lg:auto-cols-max sm:grid-cols-2 md:grid-cols-4 grid-cols-2 items-center justify-center justify-items-center">
          {
            client_images.map((image, i) => {
              return (
                <img
                  key={i}
                  className="h-auto w-36 md:w-32 sm:w-28 object-contain"
                  src={image}
                  alt="Partner"
                />
              )
            })
          }
        </div>
      </center>
    </div>
  )
}
