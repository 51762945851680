import { useState, useEffect } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon, ArrowDownCircleIcon } from '@heroicons/react/24/outline'

const navigation = [
    { name: 'About Us', href: '#inspiration' },
    { name: 'Our Solution', href: '#solutions' },
    { name: 'Our Partners', href: '#partners' },
    { name: 'Features', href: '#features' },
    { name: 'Pricing', href: '#pricing' },
    { name: 'Our Impact', href: '#impact' },
]

const logo = require('../assets/images/icon.png')
const hero_bg2 = require('../assets/images/hero_bg3.png')
const appliances = require('../assets/images/hero3.jpg')

export default function Hero() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [showHero, setShowHero] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setShowHero(true)
        }, 1000);
    }, [])

    return (
        <div className="bg-white">
            <header className="absolute inset-x-0 top-0 z-50">
                <nav className="flex items-center justify-between lg:px-8" aria-label="Global">
                    <div className="flex lg:flex-1">
                        <a href="/" className="">
                            <span className="sr-only">Afodabbo</span>
                            <img
                                className="h-36 w-auto"
                                src={logo}
                                alt=""
                            />
                        </a>
                    </div>
                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            className="inline-flex items-center justify-center rounded-md p-2.5 text-gray-200"
                            onClick={() => setMobileMenuOpen(true)}
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon className="h-8 w-8" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="hidden lg:flex lg:gap-x-10">
                        {navigation.map((item) => (
                            <a key={item.name} href={item.href} className="text-md app-semibold leading-6 text-gray-200">
                                {item.name}
                            </a>
                        ))}
                        <div className="hidden lg:block">
                            <a href="#contact" className="text-lg app-semibold text-white rounded-md bg-[#2E58A6] px-6 py-3">
                                Contact Us
                            </a>
                        </div>
                    </div>
                </nav>
                <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                    <div className="fixed inset-0 z-50" />
                    <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                        <div className="flex items-center justify-between">
                            <a href="/" className="pl-0">
                                <span className="sr-only">Afodabbo</span>
                                <img
                                    className="h-44 w-auto"
                                    src={logo}
                                    alt=""
                                />
                            </a>
                            <button
                                type="button"
                                className="rounded-full p-2.5 text-gray-700 bg-gray-200"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6">
                                    {navigation.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="-mx-3 block rounded-lg px-3 py-3 text-2xl border-b app-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                            onClick={() => setMobileMenuOpen(false)}
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Dialog.Panel>
                </Dialog>
            </header>

            <div className="relative isolate px-6 lg:px-8 h-full">
                <img
                    src={hero_bg2}
                    alt="Background"
                    className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
                />

                <div
                    className="absolute inset-x-0 -top-40 -z-20 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                    aria-hidden="true"
                >
                    <div
                        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>

                <div className="lg:grid lg:grid-cols-2 gap-4 mx-auto py-32 sm:pt-48 lg:pt-25 sm:pb-32 lg:pb-12">
                    <div>
                        <div className="lg:text-left sm:text-center md:text-center lg:pl-12 md:mx-36 lg:mx-10">
                            <h1 className="text-5xl app-bold tracking-tight text-white sm:text-7xl">
                                eCooking<br></br>is the future!
                            </h1>
                            <p className="mt-12 text-xl sm:text-2xl app-medium leading-8 text-gray-200">
                                We manufacture, sell PAYG enabled eCookers and provide the required software solutions
                                to distributors to enable them to run PAYG eCooking business.
                            </p>
                            {/* <div className="mt-10 flex items-center justify-start gap-x-6">
                                <a
                                    href="#solutions"
                                    className="transition ease-in-out delay-150 duration-300 hover:-translate-y-1 hover:scale-110 rounded-md bg-indigo-600 px-3.5 py-3 px-8 text-sm app-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Learn More
                                </a>
                                <a href="#contact" className="text-sm font-semibold leading-6 text-gray-900">
                                    Contact Us <span aria-hidden="true">→</span>
                                </a>
                            </div> */}
                        </div>
                    </div>

                    <div className='lg:-mt-5 sm:pb-36 mt-12 sm:mt-32 '>
                        <center>
                            <img
                                className={showHero ? "rounded-2xl transition-all duration-500 ease-in-out delay-150 h-fit w-auto" : "transition-all translate-x-full ease-in-out delay-150 h-fit w-auto"}
                                src={appliances}
                                alt="Woman using ricecooker"
                            />
                        </center>
                    </div>
                </div>

                <a href="#clients"><ArrowDownCircleIcon className='animate-bounce text-white hidden sm:hidden md:hidden lg:block absolute top-[85vh] left-1/2 h-14 w-14' /></a>
            </div>
        </div>
    )
}
