import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

const navigation = [
    { name: 'Home', href: '/' }
]

const logo = require('../assets/images/icon.png')
const download_qr = require('../assets/images/Afodabbo download.png')
const appliances = require('../assets/images/hero3.jpg')

const Download = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <div className="relative h-screen bg-cover bg-center bg-no-repeat" style={{ backgroundImage: `url(${appliances})` }}>
            <header className="absolute inset-x-0 top-0 z-50">
                <nav className="flex items-center justify-between lg:px-8" aria-label="Global">
                    <div className="flex lg:flex-1">
                        <Link to="/" className="">
                            <span className="sr-only">Afodabbo</span>
                            <img
                                className="h-36 w-auto"
                                src={logo}
                                alt=""
                            />
                        </Link>
                    </div>
                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            className="inline-flex items-center justify-center rounded-md p-2.5 text-gray-200"
                            onClick={() => setMobileMenuOpen(true)}
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon className="h-8 w-8" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="hidden lg:flex lg:gap-x-10">
                        {navigation.map((item) => (
                            <Link key={item.name} to={item.href} className="text-md app-semibold leading-6 text-gray-200">
                                {item.name}
                            </Link>
                        ))}
                        <div className="hidden lg:block">
                            <Link to="/#contact" className="text-lg app-semibold text-white rounded-md bg-[#2E58A6] px-6 py-3">
                                Contact Us
                            </Link>
                        </div>
                    </div>
                </nav>
                <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                    <div className="fixed inset-0 z-50" />
                    <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                        <div className="flex items-center justify-between">
                            <Link to="/" className="pl-0">
                                <span className="sr-only">Afodabbo</span>
                                <img
                                    className="h-44 w-auto"
                                    src={logo}
                                    alt=""
                                />
                            </Link>
                            <button
                                type="button"
                                className="rounded-full p-2.5 text-gray-700 bg-gray-200"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6">
                                    {navigation.map((item) => (
                                        <Link
                                            key={item.name}
                                            to={item.href}
                                            className="-mx-3 block rounded-lg px-3 py-3 text-2xl border-b app-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                            onClick={() => setMobileMenuOpen(false)}
                                        >
                                            {item.name}
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Dialog.Panel>
                </Dialog>
            </header>

            <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent sm:bg-gradient-to-t sm:from-black sm:to-transparent sm:to-90%">
                <div className="relative h-full flex flex-col justify-center sm:justify-end sm:pb-24 sm:items-center text-white p-8 sm:p-4">
                    {/* Download QR */}
                    <img
                        className="h-36 w-auto hidden sm:block rounded mb-12"
                        src={download_qr}
                        alt=""
                    />

                    <h1 className="text-4xl font-bold mb-4 sm:text-center app-bold">Experience better & cleaner cooking!</h1>
                    <p className="text-lg mb-8 sm:text-center app-light">
                        Download our consumer app to connect with our smart eCookers and accelerate <br></br> both household and enterprise eCooking.
                    </p>

                    <div className="space-y-4 sm:space-y-0 sm:space-x-4 sm:flex sm:justify-center app-semi">
                        <Link to="#" className="app-semibold inline-block bg-white text-black font-bold py-3 px-4 rounded-lg hover:bg-gray-200 transition duration-300">
                            Download on the App Store
                        </Link>
                        <Link to="#" className="app-semibold inline-block bg-white text-black font-bold py-3 px-4 rounded-lg hover:bg-gray-200 transition duration-300">
                            Download on Google Play
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Download;
