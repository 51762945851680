import {
  PresentationChartBarIcon,
  Cog8ToothIcon,
  UsersIcon,
  DevicePhoneMobileIcon,
  DocumentChartBarIcon,
} from '@heroicons/react/24/outline'

// const software_mockup = require('../assets/images/hero_bg.png')
const ecooker = require('../assets/images/ecooker.png')
const sales_app = require('../assets/images/sales_app.png')
const customer_app = require('../assets/images/customer_app.png')
const customer_management = require('../assets/images/customer_management.png')
const accounting = require('../assets/images/accounting.png')
// const support = require('../assets/images/support.png')
const telco = require('../assets/images/telco.png')

const features = [
  {
    name: 'Customer App',
    description:
      'Afodabbo Customer APP allows customer to pay for eCooker rental fees or request assistant to the sales team. Through the APP customers can see their sales reps information, usage history, payment history, cooking history and enjoy free cooking trainings. Customers uses a dedicated referral program in the APP to earn commission from the distributor.',
    icon: DevicePhoneMobileIcon,
    image: customer_app
  }, 
  {
    name: 'Sales App',
    description:
      'Afodabbo Sales APP enables sales agent to sell PAYG eCookers on the go. It combines sales, marketing, inventory management, customer management and after sale support in one tool, thereby enabling smooth collaboration. The APP has an AI-based personalized mentor for every sales agent that offer personalized suggestions based on historical data. Furthermore, the app makes tracking progress simple and aid in calculating commission.',
    icon: PresentationChartBarIcon,
    image: sales_app
  },
  {
    name: 'eCookers Device Management',
    description:
      'Our device management solution is built to secure and protect distributor eCooking appliances. A distributor is able to remotely protect a PAYG eCooker and the corporate data contained within from a single management system. There is high access control, to only those employees who have been granted access to manage the devices – user authentication and identity measures are in place to confirm that the user is authorized to access that data. Further our device management allows for wirelessly firmware update through OTA distribution while keep track eCooker device’s location.',
    icon: UsersIcon,
    image: telco
  },
  {
    name: 'eCookers Customer Management',
    description:
      'A critical aspect of PAYG Business is building a long-term relationship with a customer. Our CRM provide up-to-date information on every customer that enables a business to manage all interactions efficiently and effectively with the customer. The information ranges from customer contact details to eCooker usage summaries, customer location, usage preferences, payment history, and cooking history. The CRM allow your team to view or edit the customer profiles in real-time and keep track of non-payers promises and schedule next action. The CRM send out automatically follow up reminders where necessary and highlight if there has been no response to those reminders.',
    icon: UsersIcon,
    image: customer_management
  },
  {
    name: 'eCooking Accounting & Reporting',
    description:
      'A high-quality accounting system that handles a lot of the day-to-day work of regular bookkeeping. This includes: Updating ledger entries to track income and expenses, debt and repayment tracking, Bank and Telco reconciliations, Automatic ledger update on customer payments, Tracking asset depreciation/amortisation, Calculating tax and Managing payroll and deductions. Further the system sends a locking / unlock command to the device, digital receipts automatically to customers, create reports and make projections, giving a useful business intelligence to the distributor.',
    icon: DocumentChartBarIcon,
    image: accounting
  },
  {
    name: 'eCookers Stock Management',
    description:
      'Real-time order and stock management is the cornerstone of a PAYG business. In our cloud-based infrastructure, orders once approved are automatically transferred to the relevant department for further processing, thereby improving turnaround time. We also have a tracking tool so that you can accurately know the location of each asset. A stock reconciliation feature helps distributors to compare stock at hand against system, identify missing assets.',
    icon: Cog8ToothIcon,
    image: ecooker
  },
  // {
  //   name: 'Telco Integration',
  //   description:
  //     'Enabling distributors to send and receive SMS through the “SMS integration” and receive mobile payment to their bank accounts through “Mobile Money integration”.',
  //   icon: AdjustmentsHorizontalIcon,
  //   image: telco
  // },
]

// const hard_features = [
//   {
//     name: 'PAYG Enabled products',
//     description:
//       'Our team needs to study its API to define the action plan in order to proceed with the integration with Afodabbo. Please contact us to provide an API for our engineers to study the integration.',
//     icon: CheckCircleIcon,
//   },
//   {
//     name: 'PAYG Unabled products',
//     description: 'If you need assistance making your product paygo enabled, or you wish to have your product’s design reviewed and get advice from Afodabbo team of experienced engineers, so you can make significant progress toward a full Paygo capability, then please contact us.',
//     icon: CheckCircleIcon,
//   },

// ]

export default function Features() {
  return (
    <div className="bg-white py-24 sm:py-32" id='features'>
      {/* Software Features */}
      <div className="mx-auto max-w-7xl px-6 lg:px-8" id='software_features'>
        <div className="mx-auto max-w-2xl lg:text-center">
          <p className="text-2xl app-bold tracking-tight text-gray-600 sm:text-4xl">
            A top class, PAYG eCooking Platform
          </p>
          {/* <center>
            <img
              src={software_mockup}
              alt="Afodabbo Software"
              className="inset-0 h-full w-full object-cover object-right md:object-center"
            />
          </center> */}
        </div>

        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-10 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative px-8 py-12 shadow-md rounded-2xl">
                <img
                  src={feature.image}
                  alt="Afodabbo Software"
                  className="h-auto w-full pb-4"
                />
                <div>
                  <dt className="text-base app-semibold leading-5 text-gray-100 rounded-md bg-[#2E58A6] p-4 text-center mt-3">
                    {/* <div className="mb-6 flex h-12 w-12 items-center justify-center rounded-lg">
                    <feature.icon className="w-12 h-12 sm:w-12 sm:h-12 text-indigo-600" aria-hidden="true" />
                  </div> */}
                    {feature.name}
                  </dt>
                  <dd className="mt-4 text-justify text-[14px] leading-6 text-gray-600 app-light">{feature.description}</dd>
                </div>

              </div>
            ))}
          </dl>
        </div>
      </div>

      {/* Hardware Features */}
      {/* <div className="overflow-hidden bg-white pt-24 sm:pt-32" id='hardware_features'>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-8 lg:pt-4">
              <div className="lg:max-w-lg">
                <p className="mt-2 text-3xl app-bold tracking-tight text-gray-900 sm:text-4xl">A better workflow</p>
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  {hard_features.map((feature) => (
                    <div key={feature.name} className="relative pl-9">
                      <dt className="inline app-semibold text-gray-900">
                        <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                        {feature.name}<br></br>
                      </dt>{' '}
                      <dd className="inline">{feature.description}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
            <img
              src={hardware}
              alt="Hardware Integration"
              className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
              width={2432}
              height={1442}
            />
          </div>
        </div>
      </div> */}
    </div>
  )
}
