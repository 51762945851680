import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from '@headlessui/react'
import { InquiryForm } from "./Contact";

import { CheckCircleIcon, WrenchScrewdriverIcon, PuzzlePieceIcon } from "@heroicons/react/24/outline";

const services = [
    { title: 'Mobile App & Website', append: 'Development', price: '250', unlockPrice: '2,500', per: 'month' },
    { title: 'SMS', append: 'Integration', price: '75', unlockPrice: '1,500', per: 'month' },
    { title: 'Mobile Money', append: 'Integration', price: '100', unlockPrice: '1,500', per: 'month' },
    { title: 'Visa/Mastercard/Paypal', append: 'Integration', price: '120', unlockPrice: '3,500', per: 'month' },
]
const hardware_packages = [
    { title: 'Control Board Design', price: '4,000', per: '' },
    { title: 'Product Design', price: '3,000', per: '' },
    { title: 'Firmware Development', price: '8,000', per: '' },
    { title: 'Protocol Preparations', price: '100', unlockPrice: '2,500', per: 'hour' },
    { title: 'OEM Services', price: '1', unlockPrice: '3,600', per: 'unit' },
    { title: 'Security Testing', price: '150', unlockPrice: '3,600', per: 'hour' },
]

const module_pricing = [
    {
        title: 'eCooking Customer App',
        description: 'Continue using your existing systems, only let your customer use the Afodabbo Customer APP, upon integration.',
        price: '15',
        per: 'month'
    },
    {
        title: 'eCooking Sales App',
        description: 'Continue using your existing systems, only let your sales team use the Afodabbo Sales APP, upon integration.',
        price: '25',
        per: 'month'
    },
    {
        title: 'Device Management',
        description: 'Continue using your existing systems, only use the Afodabbo Device Management module upon integrating with your system.',
        price: '0.35',
        per: 'active device/month'
    },
    {
        title: 'Inventory Management',
        description: 'Continue using your existing systems, only use the Afodabbo Inventory Management module upon integrating with your system.',
        price: '30',
        per: 'month'
    },
    {
        title: 'Accounting Package',
        description: 'Access and manage your financial books from your computer or smartphones anytime you choose. Join business owners using Afodabbo accounting system and get huge savings',
        price: '45',
        per: 'month'
    },
    {
        title: 'Customer Relation Management',
        description: 'Continue using your existing systems, only use the Afodabbo Customer Management module upon integrating with your system.',
        price: '50',
        per: 'month'
    },
    {
        title: 'eCooker Payment SDK',
        description: 'Buy our PAYG enabled eCookers and integrate your system with our device locking / unlocking SDK.',
        price: '0.15',
        per: 'active device/month'
    },
]

const Pricing = () => {

    const [showHardware, setShowHardware] = useState(false)
    const [open, setOpen] = useState(false)
    const [inquiry, setInquiry] = useState()
    const cancelButtonRef = useRef(null)

    const handleToggle = () => {
        setShowHardware(!showHardware);
    };

    const Service = ({ title, price, unlockPrice, per, append }) => {
        return (
            <div class="mb-0 lg:mb-0">
                <div
                    class="block h-full border rounded-3xl border-primary border-opacity-20 shadow-md bg-white">
                    <div class={unlockPrice ? "border-b-2 border-neutral-100 border-opacity-100 p-6 rounded-3xl text-center" : "p-6 text-center"}>
                        <p class="mb-4 text-md bg-gray-100 border-b rounded-2xl py-6 text-[#2E58A6] app-semibold">
                            <strong>{title}<br></br>{append ? append : ''}</strong>
                        </p>
                        <h3 class="mb-2 text-4xl app-semibold">
                            <strong><span className="text-[18px] text-gray-600">USD</span> {price}</strong>
                            {per && <small class="text-base text-neutral-500">/{per}</small>}
                        </h3>
                    </div>
                    {unlockPrice &&
                        <div class="p-6 text-center">
                            <ol class="list-inside">
                                <li class="mb-2 app-bold text-[#2E58A6]">
                                    Unlock for ${unlockPrice}
                                </li>
                            </ol>
                        </div>
                    }
                    <center>
                        <button
                            // href="/#contact"
                            onClick={() => {
                                setInquiry('' + title + ' at (USD ' + price + (per ? '/' + per + ')' : ')'))
                                setOpen(true)
                            }}
                            className='relative m-5 rounded-xl border border-[#D4DEFF] bg-[#2E58A6] px-12 py-4 text-center text-base app-semibold text-white transition'
                        >
                            Purchase now
                        </button>
                    </center>

                </div>
            </div>
        )
    }

    const Module = ({ title, description, price, term }) => {
        return (
            <div className=" bg-white mx-auto mt-5 max-w-2xl rounded-3xl shadow-md ring-1 ring-gray-200 sm:mt-10 lg:mx-0 lg:flex lg:max-w-none">
                <div className="p-8 sm:p-10 lg:flex-auto">
                    <h3 className="text-2xl app-bold tracking-tight text-gray-900 border-b pb-3">{title}</h3>
                    <p className="mt-6 lg:mr-24 text-base leading-7 text-gray-600">
                        {description}
                    </p>
                </div>
                <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                    <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-5">
                        <div className="mx-auto max-w-xs px-8">
                            <p className="mt-2 flex items-baseline justify-center gap-x-2">
                                <span className="text-4xl app-bold tracking-tight text-gray-900">${price}</span>
                                <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">/{term}</span>
                            </p>
                            <button
                                // href="/#contact"
                                onClick={() => {
                                    setInquiry('' + title + ' at (USD ' + price + '/' + term + ')')
                                    setOpen(true)
                                }}
                                className='mt-10 block w-full rounded-xl border border-[#D4DEFF] bg-[#2E58A6] px-8 py-4 text-center text-base app-semibold text-white transition'
                            >
                                Purchase now
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const PricingForm = () => {

        return (
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-[6000]" initialFocus={cancelButtonRef} onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl w-full">
                                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                        <div className="sm:flex sm:items-start">
                                            <div className="mt-3 text-center w-full sm:mx-4 sm:mt-0 sm:text-left">
                                                <Dialog.Title as="h3" className="border-b pb-3 text-base app-semibold text-gray-900">
                                                    Send Inquiry
                                                </Dialog.Title>
                                                <InquiryForm inquiry={inquiry} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                            onClick={() => setOpen(false)}
                                            ref={cancelButtonRef}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        )
    }

    const PricingCard = ({
        children,
        description,
        price,
        type,
        subscription,
        buttonText,
        active,
    }) => {
        return (
            <>
                <div className="w-full px-4 md:w-1/2 lg:w-1/3">
                    <div className="relative z-10 px-8 py-10 mb-10 overflow-hidden bg-white border rounded-3xl border-primary border-opacity-20 shadow-xl sm:p-12 lg:py-10 lg:px-6 xl:p-12">
                        <span className="block mb-4 text-lg app-semibold text-primary">
                            {type}
                        </span>
                        <h2 className="mb-0 text-[42px] app-bold text-dark">
                            ${price}
                        </h2>
                        <span className="text-[16px] mb-5 app-medium text-body-color">
                            / {subscription}
                        </span>
                        <p className="my-8 border-b border-[#F2F2F2] pb-8 text-base text-gray-500 app-medium">
                            {description}
                        </p>
                        <ul className="mb-7">
                            <p className="mb-1 text-base leading-loose text-body-color">
                                {children}
                            </p>
                        </ul>
                        <button
                            // href="#contact"
                            onClick={() => {
                                setInquiry('' + type + ' at (USD ' + price + ' / ' + subscription + ')')
                                setOpen(true)
                            }}
                            className='block w-full rounded-2xl border  border-[#D4DEFF] bg-[#2E58A6] p-4 text-center text-base app-semibold text-white transition'
                        >
                            {buttonText}
                        </button>
                        <div>
                            <span className="absolute right-0 top-14 z-[-1]">
                                <svg
                                    width={77}
                                    height={172}
                                    viewBox="0 0 77 172"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle cx={86} cy={86} r={86} fill="url(#paint0_linear)" />
                                    <defs>
                                        <linearGradient
                                            id="paint0_linear"
                                            x1={86}
                                            y1={0}
                                            x2={86}
                                            y2={172}
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#3056D3" stopOpacity="0.09" />
                                            <stop offset={1} stopColor="#C4C4C4" stopOpacity={0} />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </span>
                            <span className="absolute right-4 top-10 z-[-1]">
                                <svg
                                    width={41}
                                    height={89}
                                    viewBox="0 0 41 89"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle
                                        cx="38.9138"
                                        cy="87.4849"
                                        r="1.42021"
                                        transform="rotate(180 38.9138 87.4849)"
                                        fill="#3056D3"
                                    />
                                    <circle
                                        cx="38.9138"
                                        cy="74.9871"
                                        r="1.42021"
                                        transform="rotate(180 38.9138 74.9871)"
                                        fill="#3056D3"
                                    />
                                    <circle
                                        cx="38.9138"
                                        cy="62.4892"
                                        r="1.42021"
                                        transform="rotate(180 38.9138 62.4892)"
                                        fill="#3056D3"
                                    />
                                    <circle
                                        cx="38.9138"
                                        cy="38.3457"
                                        r="1.42021"
                                        transform="rotate(180 38.9138 38.3457)"
                                        fill="#3056D3"
                                    />
                                    <circle
                                        cx="38.9138"
                                        cy="13.634"
                                        r="1.42021"
                                        transform="rotate(180 38.9138 13.634)"
                                        fill="#3056D3"
                                    />
                                    <circle
                                        cx="38.9138"
                                        cy="50.2754"
                                        r="1.42021"
                                        transform="rotate(180 38.9138 50.2754)"
                                        fill="#3056D3"
                                    />
                                    <circle
                                        cx="38.9138"
                                        cy="26.1319"
                                        r="1.42021"
                                        transform="rotate(180 38.9138 26.1319)"
                                        fill="#3056D3"
                                    />
                                    <circle
                                        cx="38.9138"
                                        cy="1.42021"
                                        r="1.42021"
                                        transform="rotate(180 38.9138 1.42021)"
                                        fill="#3056D3"
                                    />
                                    <circle
                                        cx="26.4157"
                                        cy="87.4849"
                                        r="1.42021"
                                        transform="rotate(180 26.4157 87.4849)"
                                        fill="#3056D3"
                                    />
                                    <circle
                                        cx="26.4157"
                                        cy="74.9871"
                                        r="1.42021"
                                        transform="rotate(180 26.4157 74.9871)"
                                        fill="#3056D3"
                                    />
                                    <circle
                                        cx="26.4157"
                                        cy="62.4892"
                                        r="1.42021"
                                        transform="rotate(180 26.4157 62.4892)"
                                        fill="#3056D3"
                                    />
                                    <circle
                                        cx="26.4157"
                                        cy="38.3457"
                                        r="1.42021"
                                        transform="rotate(180 26.4157 38.3457)"
                                        fill="#3056D3"
                                    />
                                    <circle
                                        cx="26.4157"
                                        cy="13.634"
                                        r="1.42021"
                                        transform="rotate(180 26.4157 13.634)"
                                        fill="#3056D3"
                                    />
                                    <circle
                                        cx="26.4157"
                                        cy="50.2754"
                                        r="1.42021"
                                        transform="rotate(180 26.4157 50.2754)"
                                        fill="#3056D3"
                                    />
                                    <circle
                                        cx="26.4157"
                                        cy="26.1319"
                                        r="1.42021"
                                        transform="rotate(180 26.4157 26.1319)"
                                        fill="#3056D3"
                                    />
                                    <circle
                                        cx="26.4157"
                                        cy="1.4202"
                                        r="1.42021"
                                        transform="rotate(180 26.4157 1.4202)"
                                        fill="#3056D3"
                                    />
                                    <circle
                                        cx="13.9177"
                                        cy="87.4849"
                                        r="1.42021"
                                        transform="rotate(180 13.9177 87.4849)"
                                        fill="#3056D3"
                                    />
                                    <circle
                                        cx="13.9177"
                                        cy="74.9871"
                                        r="1.42021"
                                        transform="rotate(180 13.9177 74.9871)"
                                        fill="#3056D3"
                                    />
                                    <circle
                                        cx="13.9177"
                                        cy="62.4892"
                                        r="1.42021"
                                        transform="rotate(180 13.9177 62.4892)"
                                        fill="#3056D3"
                                    />
                                    <circle
                                        cx="13.9177"
                                        cy="38.3457"
                                        r="1.42021"
                                        transform="rotate(180 13.9177 38.3457)"
                                        fill="#3056D3"
                                    />
                                    <circle
                                        cx="13.9177"
                                        cy="13.634"
                                        r="1.42021"
                                        transform="rotate(180 13.9177 13.634)"
                                        fill="#3056D3"
                                    />
                                    <circle
                                        cx="13.9177"
                                        cy="50.2754"
                                        r="1.42021"
                                        transform="rotate(180 13.9177 50.2754)"
                                        fill="#3056D3"
                                    />
                                    <circle
                                        cx="13.9177"
                                        cy="26.1319"
                                        r="1.42021"
                                        transform="rotate(180 13.9177 26.1319)"
                                        fill="#3056D3"
                                    />
                                    <circle
                                        cx="13.9177"
                                        cy="1.42019"
                                        r="1.42021"
                                        transform="rotate(180 13.9177 1.42019)"
                                        fill="#3056D3"
                                    />
                                    <circle
                                        cx="1.41963"
                                        cy="87.4849"
                                        r="1.42021"
                                        transform="rotate(180 1.41963 87.4849)"
                                        fill="#3056D3"
                                    />
                                    <circle
                                        cx="1.41963"
                                        cy="74.9871"
                                        r="1.42021"
                                        transform="rotate(180 1.41963 74.9871)"
                                        fill="#3056D3"
                                    />
                                    <circle
                                        cx="1.41963"
                                        cy="62.4892"
                                        r="1.42021"
                                        transform="rotate(180 1.41963 62.4892)"
                                        fill="#3056D3"
                                    />
                                    <circle
                                        cx="1.41963"
                                        cy="38.3457"
                                        r="1.42021"
                                        transform="rotate(180 1.41963 38.3457)"
                                        fill="#3056D3"
                                    />
                                    <circle
                                        cx="1.41963"
                                        cy="13.634"
                                        r="1.42021"
                                        transform="rotate(180 1.41963 13.634)"
                                        fill="#3056D3"
                                    />
                                    <circle
                                        cx="1.41963"
                                        cy="50.2754"
                                        r="1.42021"
                                        transform="rotate(180 1.41963 50.2754)"
                                        fill="#3056D3"
                                    />
                                    <circle
                                        cx="1.41963"
                                        cy="26.1319"
                                        r="1.42021"
                                        transform="rotate(180 1.41963 26.1319)"
                                        fill="#3056D3"
                                    />
                                    <circle
                                        cx="1.41963"
                                        cy="1.4202"
                                        r="1.42021"
                                        transform="rotate(180 1.41963 1.4202)"
                                        fill="#3056D3"
                                    />
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <section className="relative z-20 overflow-hidden bg-gray-100 pt-0 pb-12 lg:pt-[120px] lg:pb-[90px] border-t" id="pricing">
            <PricingForm />
            <div
                className="absolute inset-x-0 -z-20 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                aria-hidden="true"
            >
                <div
                    className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                    style={{
                        clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                />
            </div>
            {/* Package */}
            <div>
                <div className="w-full px-4">
                    <div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">
                        <h2 className="my-10 text-3xl app-bold text-dark sm:text-4xl md:text-[40px]">
                            Our Pricing
                        </h2>
                        <p className="text-base text-gray-900 app-medium">
                            We are a public benefit corporation with very competitive pricing, aiming to democratize PAYG business models for small distributors.
                        </p>
                    </div>
                </div>

                <div className="border flex items-center justify-center mb-12 py-5">
                    {/* <button
                        onClick={() => setShowHardware(false)}
                        className={showHardware ? "text-md app-semibold bg-gray-300 p-5 mx-3 rounded-xl my-3 text-gray-900" : "text-md app-semibold bg-[#2E58A6] p-5 mx-3 rounded-xl my-3 text-white"}
                    >
                        Software Packages
                    </button>
                    <button
                        onClick={() => setShowHardware(true)}
                        className={!showHardware ? "text-md app-semibold bg-gray-300 p-5 mx-3 rounded-xl my-3 text-gray-900" : "text-md app-semibold bg-[#2E58A6] p-5 mx-3 rounded-xl my-3 text-white"}
                    >
                        Hardware Packages
                    </button> */}
                    <div
                        class="app-semibold lg:text-xl text-center sm:text-xl text-sm"
                    >
                        Software Packages
                    </div>
                    {/* <div>
                        <input
                            class=" mx-3 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault" />
                    </div> */}

                    <label htmlFor="toggle" className="flex items-center cursor-pointer mx-5">
                        <div className="relative">
                            <input
                                type="checkbox"
                                id="toggle"
                                className="sr-only"
                                onChange={handleToggle}
                                checked={showHardware}
                            />
                            <div className={`block ${showHardware ? 'bg-[#2E58A6]' : 'bg-white'} border-2 border-[#2E58A6] p-2 w-14 h-8 rounded-full`}></div>
                            <div
                                className={`dot absolute left-1 top-1 bg-[#2E58A6] w-6 h-6 rounded-full transition transform ${showHardware ? 'translate-x-full bg-white' : 'translate-x-0'
                                    }`}
                            ></div>
                        </div>
                    </label>

                    <div
                        class="app-semibold lg:text-xl text-center sm:text-xl text-sm"
                    >
                        Hardware Packages
                    </div>
                </div>

                {/* Software Packages */}
                {!showHardware && <div className="flex flex-wrap justify-center mb-5 mx-1 lg:mx-4 sm:mx-4" id="software_packages">

                    <div className="flex flex-wrap mx-4">
                        <PricingCard
                            type="Silver"
                            price="3,500"
                            subscription="Month"
                            description="You are a new eCooker distributor entering the market, with less than 5,000 customers."
                            buttonText="Purchase Silver"
                        >
                            <List>10 User Accounts</List>
                            <List>Afodabbo Sales APP</List>
                            <List>Afodabbo Customer APP</List>
                            <List>Customer Management</List>
                            <List>Inventory and Device Management</List>
                            <List>Telco Integration</List>
                            <List>One-year eCooker warrant</List>

                        </PricingCard>

                        <PricingCard
                            type="Diamond"
                            price="0.3"
                            subscription="Active Customer / Month"
                            description="You are an established eCooker distributor with more than 5,000 customers."
                            buttonText="Purchase Diamond"
                        >
                            <List>All Silver Features</List>
                            <List>Extra 2,000 user accounts</List>
                            <List>Client data ownership</List>
                            <List>Accounting basic features</List>
                            <List>CSV Data Exports</List>
                            <List>60 hours/month support</List>
                            <List>Access to Customer contracts</List>
                        </PricingCard>

                        <PricingCard
                            type="Gold"
                            price="0.5"
                            subscription="Active Customer / Month"
                            description="You are a well positioned eCooker distributor with notable market share and a robust distribution chain."
                            buttonText="Purchase Gold"
                        >
                            <List>All Diamond Features</List>
                            <List>Unlimited user accounts</List>
                            <List>Customised Dashboards</List>
                            <List>Customised Mobile APPs</List>
                            <List>24 hours support</List>
                            <List>Market exclusivity</List>
                            <List>All Accounting features</List>
                        </PricingCard>
                    </div>
                </div>}

                {/* Hardware Packages */}
                {showHardware && <div class="my-4 mx-auto md:px-6" id="hardware_packages">
                    <section class="pb-12">
                        <center>
                            <p className="text-md app-semibold my-3 text-gray-900">
                                Hardware Packages
                            </p>
                        </center>

                        <div class="lg:grid gap-6 lg:grid-cols-3 lg:gap-x-5 mx-4 justify-center place-content-center">
                            {hardware_packages.map((service, i) => (
                                <Service
                                    key={i}
                                    title={service.title}
                                    price={service.price}
                                    unlockPrice={service.unlockPrice}
                                    per={service.per}
                                />
                            ))}
                        </div>
                    </section>
                </div>}

            </div>

            {/* Module Pricing */}
            {!showHardware && <div className="border-t-2 border-white py-24 sm:py-16">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="flex flex-col items-center justify-center">
                        <div className="bg-[#2E58A6] p-8 rounded-full mb-4">
                            <PuzzlePieceIcon className="h-8 text-white" />
                        </div>
                        <h2 class="mb-12 text-center text-2xl app-bold">Module Pricing</h2>
                    </div>

                    {module_pricing.map((module, i) => {
                        return (
                            <Module
                                title={module.title}
                                description={module.description}
                                price={module.price}
                                term={module.per}
                            />
                        )
                    })}
                </div>
            </div>}

            {/* Ser3vices Pricing */}
            {!showHardware && <div class="container my-4 border-t-2 border-white mx-auto md:px-6">
                <section class="my-16">
                    <div className="flex flex-col items-center justify-center">
                        <div className="bg-[#2E58A6] p-8 rounded-full mb-4">
                            <WrenchScrewdriverIcon className="h-8 text-white" />
                        </div>
                        <h2 class="mb-12 text-center text-2xl app-bold">Services Pricing</h2>
                    </div>


                    <div class="grid gap-6 lg:grid-cols-4 lg:gap-x-5 mx-3">
                        {services.map((service, i) => (
                            <Service
                                key={i}
                                title={service.title}
                                price={service.price}
                                unlockPrice={service.unlockPrice}
                                per={service.per}
                                append={service.append}
                            />
                        ))}
                    </div>
                </section>
            </div>}

        </section>
    );
};

export default Pricing;

const List = ({ children }) => {
    return (
        <div className="flex w-full justify-start content-center">
            <CheckCircleIcon className="h-8 w-auto mr-3 text-[#2E58A6]" />
            <p className="mb-3 flex-1 app-light text-body-color">{children}</p>
        </div>
    );
};
