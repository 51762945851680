export const userAuth = async () => {

    const response = await fetch('https://api.prod.afodabbo.com/authentication/application/gettoken', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "username": "capp@afodabbo.com",
            "password": "Afbo@admin1"
        }),
    });

    return response.json()

    // response.json().then((data) => {
    //     accessToken = data
    // }).catch((error) => {
    //     return error
    // })

}